import { DataContext } from "DataContainer";
import React, { useContext, useEffect } from "react";

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const RTL = React.lazy(() => import("./RTL"));
const LTR = React.lazy(() => import("./LTR"));

const ThemeSelector = ({ children }) => {
  const { setLang } = useContext(DataContext);

  const pathname = window.location.pathname;

  const lang = pathname.includes("/ar") ? "ar" : "en";

  useEffect(() => {
    if (pathname.includes("/ar")) {
      setLang("ar");
    } else {
      setLang("en");
    }
  }, [pathname, setLang]);

  return (
    <>
      <React.Suspense fallback={() => null}>
        {lang === "ar" && <RTL />}
        {lang === "en" && <LTR />}
      </React.Suspense>
      <div dir={lang === "ar" ? "rtl" : "ltr"}>{children}</div>
    </>
  );
};

export default ThemeSelector;
