export default {
  en: {
    title: "Leading B2B Event Management in Saudi Arabia",
    aboutUS: "About US",
    aboutText: [
      "We organize certified trainings, private and Government-lead conferences, seminars and exhibitions in the Kingdom across key industry verticals. ",
      "These include, but not limited to:",
    ],
    aboutInclusion: [
      { title: "Technology; IoT, AI", image: "ai.png" },
      { title: "Oil & Gas", image: "oil.png" },
      { title: "Mining", image: "mining.png" },
      { title: "Petrochemical & Manufacturing ", image: "petrochemical.png" },
      { title: "Construction & Infrastructure", image: "construction.png" },
      { title: "Health & Well-being ", image: "health.png" },
      { title: "Education & Training ", image: "training.png" },
      { title: "Human Resources", image: "hr.png" },
      { title: "Safety, Fire Protection & Security", image: "safety.png" },
      { title: "Environment & Sustainability", image: "sustain.png" },
    ],
    conferences: {
      title: "Conferences",
      text: [
        "Our conferences on emerging technologies and future trends are thoroughly researched & well curated to ensure well-curated programs, industry leading speakers, pre-qualified delegates and leading vendors to showcase new solutions. Each event platform offers 1-2-1 networking, learning and the chance to explore new collaborations and business opportunities. We work towards building connections between delegates and vendors for successful networking and flawless onsite management via event professionals and use of advanced Tech- leads to a memorable event experience for all.",
        "With our established network and relations in Saudi Arabia, we specialize in organizing medium to large-scale conferences and summits that attract the relevant decision makers and stakeholders.",
      ],
    },
    trainings: {
      title: "Trainings",
      text: [
        "MC offers In-House Training and Public Programs as per client requirements that ensures the desired outcome of skill development and learning. After a detailed brief from the client, we engage with leading industry-recognized trainers that offer certified Technical/commercial training programs.",
        "We offer Trainings across HR, Cyber Security, HSE, Oil & Gas, Shipping and Leadership programs. Contact us to discuss your training requirement in detail.",
      ],
    },
    managedEvents: {
      title: "Managed Events",
      text: [
        "With on-going established connections with Industry stakeholders, leaders and decision-makers of key sectors in Saudi Arabia, we can organize bespoke seminars, meetings and conferences to take your brand and solution in front of your target audience. With a well curated agenda, keynote speakers and on-site management, we can take care of your event requirements that leads to quick ROI and brand awareness. Contact us to help us understand your requirements and build your event.",
      ],
    },
    support: {
      title: "Our events can support you in",
      points: [
        "Flawless market entry in Saudi Arabia with post event support",
        "Lead generation of your qualified target audience",
        "Brand awareness and brand positioning",
        "Customer awareness and support via bespoke trainings and seminars",
        "Direct meetings with Government bodies and industry leaders via Summits we organize",
      ],
    },
    connect: "Connect With Us",
    contact: {
      title: "Contact Us",
    },
    services: "Services",
    home: { title: "Home" },
    upcoming: { title: "Upcoming Events" },
  },
  ar: {
    title: "قيادة إدارة الفعاليات B2B في المملكة العربية السعودية",
    aboutUS: "عن مؤسسة التواصل التسويقي",
    aboutText: [
      "مؤسسة التواصل التسويقي هي مؤسسة رائدة في إدارة المعارض و المؤتمرات مقرها الدمام، السعودية. تتميز المؤسسة بوجود فريق لديه خبرة عالية في إدارة الفعاليات.",
      "ننظم دورات تدريبية معتمدة ، مؤتمرات خاصة و حكومية ، ندوات و معارض في المملكة عبر قطاعات الصناعة الرئيسية. تشمل ، على سبيل المثال لا الحصر :",
    ],
    aboutInclusion: [
      { title: "الصحة والسلامة", image: "health.png" },
      { title: "الغاز والنفط", image: "oil.png" },
      { title: "البتروكيماويات", image: "petrochemical.png" },
      { title: "التصنيع", image: "manufacturing.png" },
      { title: "البناء والبنية التحتية", image: "construction.png" },
      { title: "الموارد البشرية", image: "hr.png" },

      { title: "النفط والغاز ", image: "ai.png" },
      { title: "التعدين", image: "oil.png" },
      { title: "التصنيع والبتروكيماويات ", image: "mining.png" },
      { title: "التشييد والبناء ", image: "petrochemical.png" },
      { title: "التشييد والبناء ", image: "construction.png" },
      { title: "الصحة و الرفاهية ", image: "health.png" },
      { title: "التعلم والتدريب", image: "training.png" },
      { title: "الموارد البشرية ", image: "hr.png" },
      { title: "السلامة والحماية من الحريق والأمن ", image: "safety.png" },
      { title: "البيئة و الإستدامة", image: "sustain.png" },
    ],
    conferences: {
      title: "المؤتمرات",
      text: [
        "مؤتمراتنا حول التقنيات الناشئة و الاتجاهات المستقبلية يتم تنظيمها بدقة لضمان برامج ذات جودة عالية. تتضمن متحدثين رائدين في الصناعة ، و كبار الشركات ذات العلاقة لعرض حلول جديدة. كل حدث يقدم فرص تواصل ، التقاء ، تعلم ، فرص استكشاف أوجه التعاون و فرص الأعمال الجديدة. نحن نعمل لبناء علاقات بين المندوبين و البائعين من خلال منظمين محترفين و استخدام تقنية متقدمة لإحياء حدث و تجربة فريدة.",
        "من خلال علاقاتنا الراسخة مع شركائنا في المملكة و خارجها ، نحن متخصصون في بناء مؤتمرات مميزة متوسطة الى كبيرة الحجم و التي تجذب صانعي القرار المعنيين و أصحاب المصلحة.",
      ],
    },
    trainings: {
      title: "التدريب",
      text: [
        "مؤسسة التواصل التسويقي تقدم تدريب داخلي أو برامج عامة حسب متطلبات العميل و تضمن لكم النتيجة المرجوة لتنمية المهارات و التعلم. بعد فهم متطلبات العميل، نتعامل مع مدربين رائدين معترف بهم في الصناعة و يقدمون دورات تدريبية معتمدة. نقدم دورات تدريبية في مختلف المجالات مثل : الموارد البشرية ، الأمن السيبراني ، الصحة و السلامة والبيئة ، الغاز و النفط ، برامج الشحن ، الإمداد والقيادة.  تواصل معنا لمناقشة متطلباتك التدريبية بالتفصيل.",
      ],
    },
    managedEvents: {
      title: "تنظيم الأحداث",
      text: [
        "من خلال علاقاتنا القائمة و المستمرة مع أصحاب المصلحة ، القادة ، وصناع القرار في قطاع الصناعة الرئيسي في المملكة العربية السعودية ، نستطيع تنظيم ندوات متخصصة ، اجتماعات ومؤتمرات لإيصال فكرة علامتكم التجارية والحلول الخاصة بكم لجمهوركم المستهدف. من خلال جدول أعمال منسق ومتحدثين رئيسيين ، يمكننا رعاية الحدث والمتطلبات الخاصة بكم التي تؤدي إلى عائد استثمار سريع ونشر التوعية بالعلامة التجارية الخاصة بكم. تواصل معنا لنستطيع فهم رغباتك و متطلباتك وإقامة الحدث الخاص بك. ",
      ],
    },
    support: {
      title: "فريقنا يستطيع دعمكم في",
      points: [
        "دخول السوق في المملكة مع دعم ما بعد الحدث. ",
        "جيل رائد من جمهورك المستهدف. ",
        "توعية العملاء ودعمهم من خلال الدورات التدريبية والندوات المخصصة.",
      ],
    },
    connect: "اتصل بنا",
    contact: {
      title: "اتصل بنا",
    },
    services: "خدمات",
    home: { title: "رئيسية" },
    upcoming: { title: "الأحداث القادمة" },
  },
};
