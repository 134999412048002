import React from "react";
import { Container, Row, Col, Card, Button } from "reactstrap";

function Stats({ conferences }) {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center text-white pb-4 text-uppercase">
                <b>OUR CONFERENCES</b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {upcoming.map((c, i) => (
              <Col lg={3} xs={12} key={i} className="text-center my-4 px-1">
                <Card style={{ borderRadius: 0, backgroundColor: "transparent" }}>
                  <a href={c.link}>
                    <div
                      className="p-2"
                      style={{
                        backgroundImage:
                          "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='5' stroke-dasharray='15%2c 15%2c 1' stroke-dashoffset='55' stroke-linecap='square'/%3e%3c/svg%3e\")",
                      }}
                    >
                      <img
                        src={require(`assets/images/logo/${c.image}`)}
                        width="100%"
                        alt={c.name}
                        style={{ borderRadius: 0 }}
                        className="bg-white"
                      />
                    </div>
                    <div className="px-3 bg-white pb-4" style={{ marginTop: -10 }}>
                      <h3 className="text-dark text-700">
                        {c.title}
                        <br />
                        {c.location}
                      </h3>
                      <h3>{c.description}</h3>
                      <Button
                        block
                        className="btn text-700 mt-3"
                        color="info"
                        size="sm"
                        href={c.link}
                      >
                        Learn More
                      </Button>
                    </div>
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        {/* <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-center text-white text-bold pb-4 text-uppercase">
                <b>Previous Events</b>
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {previous.map((c, i) => (
              <Col lg={3} xs={6} key={i} className="text-center my-4 px-3">
                <Card style={{ borderRadius: 0 }}>
                  <a href={c.link}>
                    <img
                      src={require(`assets/images/logo/${c.image}`)}
                      width="100%"
                      alt={c.name}
                      style={{ borderRadius: 0 }}
                    />
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </Container> */}
      </div>
    </>
  );
}
const upcoming = [
  {
    title: "Digital Twin Summit 2024",
    location: "Riyadh, Saudi Arabia",
    description: "Oct 8 - 9,  2024",
    link: "https://ksa.digitaltwin-summit.com",
    image: "DT-KSA-2024.png",
  },
  {
    title: "Digital Twin Summit 2024",
    location: "London, UK",
    description: "Oct 30-31, 2024",
    link: "https://uk.digitaltwin-summit.com",
    image: "DT-UK-LOGO-2024.png",
  },
  {
    title: "Digital Twin Summit 2024",
    location: "Dubai, UAE",
    description: "Feb 20-21, 2024",
    link: "https://me.digitaltwin-summit.com",
    image: "DT-ME-2024.png",
  },
  {
    title: "New Zealand Digital Twin 2024",
    location: "Auckland, New Zealand",
    description: "May 8-9, 2024",
    link: "https://nz.digitaltwin-summit.com",
    image: "nzlogoblack.png",
  },

  {
    title: "Egypt Digital Construction",
    link: "https://edc.saifee-events.com",
    image: "edc.png",
  },
  {
    image: "WADC-logo.png",
    title: "West Africa Digital Construction",
    link: "/#",
  },
  {
    image: "EADC-logo.png",
    title: "East Africa Digital Construction",
    link: "/#",
  },
];
const previous = [
  {
    title: "dtsa",
    link: "https://ksa.digitaltwin-summit.com",
    image: "dtsa.png",
  },
];

export default Stats;
