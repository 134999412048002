import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import text from "views/text";

function Conferences() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const { lang } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={5}>
              <div className="mt-5" style={{ position: "sticky", top: size ? "8rem" : "3rem" }}>
                <h1 className=" text-white">Managed Events</h1>
                <h3 className="text-white mt-5">
                  With on-going established connections with Industry stakeholders, leaders and
                  decision-makers of key sectors in Saudi Arabia, we can organize bespoke seminars,
                  meetings and conferences to take your brand and solution in front of your target
                  audience. With a well curated agenda, keynote speakers and on-site management, we
                  can take care of your event requirements that leads to quick ROI and brand
                  awareness. Contact us to help us understand your requirements and build your
                  event.
                </h3>
              </div>
            </Col>
            <Col lg={5}>
              <div className="py-3">
                <img
                  src={require("assets/photos/13.jpg")}
                  width="100%"
                  alt="perception"
                  style={{
                    border: "1px solid #fff",
                  }}
                />
              </div>
            </Col>
            <Col lg={6} className="align-self-center"></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Conferences;
