import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import text from "views/text";

function Conferences() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const { lang } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={5}>
              <div className="mt-5" style={{ position: "sticky", top: size ? "8rem" : "3rem" }}>
                <h1 className=" text-white">Conferences</h1>
                <h3 className="text-white mt-5">
                  Our conferences on emerging technologies and future trends are thoroughly
                  researched & well curated to ensure quality agendas, industry leading speakers,
                  pre-qualified delegates and leading vendors to showcase new solutions. Each event
                  platform offers engaging speakers, 1-2-1 networking, learning and the chance to
                  explore new collaborations and business opportunities. The team ensures
                  match-making between delegates and vendors for successful networking and our
                  flawless onsite management via event professionals and use of advanced Tech- leads
                  to a memorable event experience for all. Contact us to know more about our
                  upcoming conferences
                </h3>
              </div>
            </Col>
            <Col lg={5}>
              <div className="py-3">
                <img
                  src={require("assets/photos/2.jpg")}
                  width="100%"
                  alt="perception"
                  style={{
                    border: "1px solid #fff",
                  }}
                />
              </div>
              <div className="py-3">
                <img
                  src={require("assets/photos/17.jpg")}
                  width="100%"
                  alt="perception"
                  style={{
                    border: "1px solid #fff",
                  }}
                />
              </div>
            </Col>
            <Col lg={6} className="align-self-center"></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Conferences;
