// reactstrap components
import React, { useContext, useEffect } from "react";

import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { DataContext } from "DataContainer";
import text from "views/text";

function LandingPageHeader({ location }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  useEffect(() => {
    const common = {
      containment: "#bgndVideo",
      autoPlay: true,
      mute: true,
      startAt: 20,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "VtN2iOjQxSY",
        ...common,
      },
    ];

    window.jQuery("#bgndVideo").YTPlaylist(videos, false);
  }, []);

  const { lang } = useContext(DataContext);

  return (
    <div
      className="section py-0"
      style={{ backgroundColor: "transparent", minHeight: size && "100vh" }}
    >
      <div
        style={{
          background: "#000",
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: -100,
          marginLeft: "auto",
          marginRight: "auto",
          pointerEvents: "none",
          marginTop: !size && "2.5rem",
        }}
      >
        <div id="bgndVideo" className="section player"></div>
      </div>

      <div
        className="page-header"
        style={{
          backgroundColor: "rgba(255,255,255,0.7)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          minHeight: size ? "100vh" : "40vh",
          maxHeight: "999px",
          overflow: "hidden",
          position: "relative",
          width: "100%",
          zIndex: "1",
          display: "flex",
          alignItems: "center",
          justifyCoontent: "center",
        }}
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center align-self-center d-flex">
            <Col lg={10} className="mt-5 pt-5 text-center text-dark">
              <h1 style={{ fontSize: size ? "8vw" : "14vw", fontWeight: "400" }}>Saifee Events</h1>
              <h3 style={{ fontSize: size ? "3vw" : "5vw" }}>
                Knowledge is prolific, when it brings people together.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LandingPageHeader;
