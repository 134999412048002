import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import text from "views/text";

function TextGrid() {
  let pageHeader = React.createRef();
  const { lang } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h1 className="text-white">About Us</h1>

              <h3 className="text-white mt-5">
                Saifee Events brings more than 35 years of combined experience to be your agile
                Go-to partner for business trainings and conferences.
                <br />
                <br />
                We will manage your on-going requirements of customised & certified training
                programs for your employees.
                <br />
                <br />
                We Provide exclusive platforms to connect with clients & network with industry
                shapers.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
