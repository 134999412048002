import React, { useContext } from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { DataContext } from "DataContainer";
import text from "views/text";

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const { lang } = useContext(DataContext);

  return (
    <>
      <Navbar
        className={`fixed-top ${scroll ? "navbar-transparent" : "bg-white"}`}
        style={{ minHeight: "40px" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" to="/" target="_blank" className="p-0" tag={Link}>
              <img
                src={require(`assets/images/logo/logo-dark.png`)}
                className="p-3"
                width="100%"
                style={{ maxWidth: "150px" }}
                alt="logo"
              />
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler text-right navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-dark" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar style={{ zIndex: 1000000 }}>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary pt-4 px-2 text-dark`}
                    style={{
                      backgroundColor: "transparent",
                    }}
                    href={s.link}
                  >
                    <i className={`fa fa-${s.name}`} style={{ fontSize: "1.5rem" }} />
                  </a>
                ))}
              </NavItem>
              {/* <NavItem className="d-flex align-middle">
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="navbar-item-custom  ml-2 text-dark">
                    <i class="fa fa-globe" aria-hidden="true"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {["en", "ar"].map((i) => (
                      <DropdownItem href={`/${i}`} key={i} className="text-400 text-uppercase">
                        {i}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem> */}
              {menu.map((link, i) => (
                <NavItem key={i} className="">
                  {window.location.pathname === `/` && link.page === "/" ? (
                    <AnchorLink
                      offset="0"
                      href={`#${link.link}`}
                      onClick={toggleNavbarCollapse}
                      className="nav-link navbar-item-custom  ml-2 text-dark"
                    >
                      {text[lang][link.slug].title}
                      <hr className="m-0 mt-1 p-0" align="center" />
                    </AnchorLink>
                  ) : link.page ? (
                    <a
                      href={`#${link.link}`}
                      className="nav-link navbar-item-custom  ml-2 text-dark"
                    >
                      {text[lang][link.slug].title}
                      <hr className="m-0 mt-1 p-0" align="center" />
                    </a>
                  ) : (
                    <a
                      href={`/${link.link}`}
                      className="nav-link navbar-item-custom  ml-2 text-dark"
                    >
                      {text[lang][link.slug].title}
                      <hr className="m-0 mt-1 p-0" align="center" />
                    </a>
                  )}
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  {
    name: "Conferences",
    link: "conferences",
    slug: "conferences",
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: "Managed Events",
    link: "managedEvents",
    slug: "managedEvents",
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: "Trainings",
    link: "trainings",
    slug: "trainings",
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: "Contact Us",
    link: "contact",
    slug: "contact",
    page: "/",
    items: undefined,
    grid: 1,
  },
];

const social = [
  // {
  //   name: 'facebook',
  //   link: '',
  // },
  // { name: 'instagram', link: '' },
  // {
  //   name: 'twitter',
  //   link: '',
  // },
  // {
  //   name: 'youtube',
  //   link: '',
  // },
  // {
  //   name: 'whatsapp',
  //   link: '',
  // },
];
