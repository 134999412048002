import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import text from "views/text";
function Sensors() {
  let pageHeader = React.createRef();

  const { lang } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className=" text-white mb-5">{text[lang].support.title}:</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12}>
              <ul>
                {text[lang].support.points.map((c) => (
                  <li className="text-white">
                    <h3 className="text-white my-2">{c}</h3>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sensors;
