import React from "react";
import Slider from "react-slick";

import { Container, Row, Col, Card } from "reactstrap";

function Sponsor() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const settings = {
    dots: true,
    autoplay: true,
    arrows: size,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 3,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1 className="text-center text-dark mb-4">Our Sponsors & Partners</h1>
          <Row className="justify-content-center">
            <Col lg={11} className={"ml-auto mr-auto mb-4"}>
              <Slider {...settings}>
                {sponsors.map((s, index) => (
                  <a href={s.link} className="px-3">
                    <Card style={{ borderRadius: 0, maxWidth: "350px" }} className="mx-auto">
                      <img
                        src={s.image}
                        className="mx-auto"
                        alt={s.name}
                        height="100%"
                        style={{ borderRadius: 0 }}
                      />
                    </Card>
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
        {/* <Container>
          <h1
            className="text-center text-g"
            style={{
              fontSize: "3rem",
            }}
          >
            2022 PARTNERS
          </h1>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s, i) => (
                  <Col lg={s.lg || 4} className={"mx-auto"} key={i}></Col>
                ))
              )}
            </Row>
          ))}
        </Container> */}
      </div>
    </>
  );
}

export default Sponsor;
export const sponsors = [
  {
    name: "Invicara	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1682489560477-invicara_owler_20160227_200730_original.png",
  },
  {
    name: "One Click BIM	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1693369728541.png",
  },
  {
    name: "Cohesive	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1681434192347-Cohesive_Logo.png",
  },
  {
    name: "PLANRADAR	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1686200644864.jpg",
  },
  {
    name: "Athenophilia	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1680439124239-Athenophilia_%281%29.jpg",
  },
  {
    name: "DTP NZ	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120005/sponsors/1697453706514.png",
  },
  {
    name: "KEO	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1690971463685.png",
  },

  {
    name: "PMI UAE	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1676368638746-PMIUA.jpg",
  },
  {
    name: "RICS	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1673891961097-RICS.png",
  },
  {
    name: "Jassera Engineering	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1678335889482-Jassera.jpg",
  },
  {
    name: "Autodesk	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120003/sponsors/1698235394815.jpg",
  },
  {
    name: "Mashura Advanced Consulting	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1693850640439.png",
  },
  {
    name: "Smarttwin	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1678158390790-Smarttwin.jpg",
  },
  {
    name: "ESRI	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1690971114053.png",
  },
  {
    name: "MipMap-Geotech	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1692938315277.jpg",
  },
  {
    name: "TWINVIEW	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1692079196254.png",
  },
  {
    name: "ICE Mena	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1690971629857.png",
  },
  {
    name: "SCA	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1693748673419.png",
  },
  {
    name: "DASSAULT SYSTEMES	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1671808383984-dassault.png",
  },
  {
    name: "Tantt	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1696208392907.png",
  },
  {
    name: "Trimble	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1693916263789.png",
  },

  {
    name: "Dar	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1694345049801.jpg",
  },
  {
    name: "Sigma Navvis	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1675938177168-Sigma_and_NavVis_%281%29.jpg",
  },
  {
    name: "Omnix Unity	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1675937952218-omnix.jpg",
  },
  {
    name: "PLANRADAR	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1671201553967-planradar.jpg",
  },
  {
    name: "JACOBS	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1692079278313.png",
  },
  {
    name: "Women in BIM	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1684734632022-Detailed_Logo_WIB_.png",
  },
  {
    name: "CIOB	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1671201517014-CIOB1.png",
  },

  {
    name: "Autodesk	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1680526124211-WhatsApp_Image_2023-04-03_at_18.18.01.jpeg",
  },
  {
    name: "Ujin Technologies	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1677659148952-IMG_9134.PNG",
  },
  {
    name: "Bright Spaces Tech	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1693903263601.png",
  },
  {
    name: "Dar	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1676366082755-Screenshot_2023-02-14_at_2.42.48_PM.jpg",
  },
  {
    name: "BSI	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1681900534333-bsi.png",
  },
  {
    name: "DIGITAL TWIN CONSORTIUM	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1695122953333.png",
  },
  {
    name: "Omnix	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1690971684201.png",
  },
  {
    name: "Zero	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1685755789434.png",
  },
  {
    name: "Urbi	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1677111535478-yellow.png",
  },
  {
    name: "PLANRADAR	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1690971197881.jpg",
  },
  {
    name: "Lieca Geosystems	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1676509528187-leica.jpg",
  },
  {
    name: "IET	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1681279982821-IET_Master_Logo_CMYK_NEW.png",
  },
  {
    name: "RICS	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9c1ea42c-a8dc-11ed-afa1-0242ac120002/sponsors/1686051225268.png",
  },
  {
    name: "Khatib & Alami	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1675938234653-1673894843257-69bd3694efedd00ce00d11bb12179fac.jpg",
  },

  {
    name: "Bentley	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/9f70fe34-7d4b-11ed-a1eb-0242ac120002/sponsors/1676624145496-BentleyLOGO_GREEN_complete.png",
  },
  {
    name: "DASSAULT SYSTEMES	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1690971149535.png",
  },
  {
    name: "Siemens	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1695045179478.png",
  },
  {
    name: "Geomatex	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1695044852216.jpg",
  },
  {
    name: "RICS	",
    image:
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120002/sponsors/1688977255831.png",
  },
];
