import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Row, Container, Col, Button } from "reactstrap";
import text from "views/text";
function DemoFooter() {
  const { lang } = useContext(DataContext);
  return (
    <>
      <footer className="footer pt-5 py-3" style={{ background: "#fff" }}>
        <Container>
          <Row className="justify-content-center py-5">
            <Col lg={4} className="py-2 text-center">
              <a href="https://saifee-events.com/">
                <img
                  src={require(`assets/images/logo/logo-dark.png`)}
                  style={{ maxWidth: "300px" }}
                  width="100%"
                  alt="Finnovex"
                />
              </a>
            </Col>
            <Col lg={4} className="py-2">
              <h2 className="d-inline text-dark border-bottom w-100 pb-2 ">
                {text[lang].services}
              </h2>
              <h3 className="mt-4">
                <a href="#conferences" className="text-dark" style={{ textDecoration: "none" }}>
                  {text[lang].conferences.title}
                </a>
              </h3>
              <h3 className="mt-4">
                <a href="#trainings" className="text-dark" style={{ textDecoration: "none" }}>
                  {text[lang].trainings.title}
                </a>
              </h3>
              <h3 className="mt-4">
                <a href="#managedEvents" className="text-dark" style={{ textDecoration: "none" }}>
                  {text[lang].managedEvents.title}
                </a>
              </h3>
            </Col>
            <Col lg={4}>
              <h3 className="mb-0 text-dark">{text[lang].connect}</h3>
              {social.map((s) => (
                <Button
                  className="btn-neutral btn-just-icon m-1 p-1"
                  style={{ color: "#000", background: "transparent" }}
                  href={s.link}
                >
                  <i className={`fa fa-${s.name}`} />
                </Button>
              ))}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} className={"text-center text-dark mt-2"}>
              <p>Copyright © Saifee-Events {new Date().getFullYear()}. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default DemoFooter;

const social = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/saifee/?originalSubdomain=in",
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/people/Saifee-Events-Pvt-Ltd/100068364845227/",
  },
  {
    name: "twitter",
    link: "https://twitter.com/hashtag/SaifeeEvents?src=hash",
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/channel/UCjvV28qa-9aEuqdeA0zsqaQ",
  },
];
