import React from "react";

import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import { Col, Container, Row } from "reactstrap";
function RegisterPage() {
  return (
    <>
      <ProfilePageHeader title="Privacy Policy" />
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Effective date: June 01, 2018</h2>
            <p>
              Saifee Events Pvt Ltd (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;)
              operates the https://saifee-events.com website (the &ldquo;Service&rdquo;).
            </p>
            <p>
              This page informs you of our policies regarding the collection, use, and disclosure of
              personal data when you use our Service and the choices you have associated with that
              data.
            </p>
            <p>
              We use your data to provide and improve the Service. By using the Service, you agree
              to the collection and use of information in accordance with this policy. Unless
              otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions, accessible from
              https://saifee-events.com&nbsp;
            </p>
            <br />
            <h2>Information Collection And Use</h2>
            <p>
              We collect several different types of information for various purposes to provide and
              improve our Service to you.
            </p>
            <p>Types of Data Collected</p>
            <p>Personal Data</p>
            <p>
              While using our Service, we may ask you to provide us with certain personally
              identifiable information that can be used to contact or identify you (&ldquo;Personal
              Data&rdquo;). Personally identifiable information may include, but is not limited to:
            </p>
            <ul>
              <li>
                <p>Email address</p>
              </li>
              <li>
                <p>First name and last name</p>
              </li>
              <li>
                <p>Phone number</p>
              </li>
              <li>
                <p>Address, State, Province, ZIP/Postal code, City</p>
              </li>
              <li>
                <p>Cookies and Usage Data</p>
              </li>
            </ul>
            <h2>Tracking &amp; Cookies Data</h2>
            <p>
              We use cookies and similar tracking technologies to track the activity on our Service
              and hold certain information.
            </p>
            <p>
              Cookies are files with small amount of data which may include an anonymous unique
              identifier. Cookies are sent to your browser from a website and stored on your device.
              Tracking technologies also used are beacons, tags, and scripts to collect and track
              information and to improve and analyze our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be able to use some
              portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <ul>
              <li>
                <p>Session Cookies. We use Session Cookies to operate our Service.</p>
              </li>
              <li>
                <p>
                  Preference Cookies. We use Preference Cookies to remember your preferences and
                  various settings.
                </p>
              </li>
              <li>
                <p>Security Cookies. We use Security Cookies for security purposes.</p>
              </li>
            </ul>
            <h2>Use of Data</h2>
            <p>Saifee Events Pvt Ltd uses the collected data for various purposes:</p>
            <ul>
              <li>
                <p>To provide and maintain the Service</p>
              </li>
              <li>
                <p>To notify you about changes to our Service</p>
              </li>
              <li>
                <p>
                  To allow you to participate in interactive features of our Service when you choose
                  to do so
                </p>
              </li>
              <li>
                <p>To provide customer care and support</p>
              </li>
              <li>
                <p>
                  To provide analysis or valuable information so that we can improve the Service
                </p>
              </li>
              <li>
                <p>To monitor the usage of the Service</p>
              </li>
              <li>
                <p>To detect, prevent and address technical issues</p>
              </li>
            </ul>
            <h2>Transfer Of Data</h2>
            <p>
              Your information, including Personal Data, may be transferred to &mdash; and
              maintained on &mdash; computers located outside of your state, province, country or
              other governmental jurisdiction where the data protection laws may differ than those
              from your jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of such information
              represents your agreement to that transfer.
            </p>
            <p>
              Saifee Events Pvt Ltd will take all steps reasonably necessary to ensure that your
              data is treated securely and in accordance with this Privacy Policy and no transfer of
              your Personal Data will take place to an organization or a country unless there are
              adequate controls in place including the security of your data and other personal
              information.
            </p>
            <p>Disclosure Of Data</p>
            <p>Legal Requirements</p>
            <p>
              Saifee Events Pvt Ltd may disclose your Personal Data in the good faith belief that
              such action is necessary to:
            </p>
            <ul>
              <li>
                <p>To comply with a legal obligation</p>
              </li>
              <li>
                <p>To protect and defend the rights or property of Saifee Events Pvt Ltd</p>
              </li>
              <li>
                <p>To prevent or investigate possible wrongdoing in connection with the Service</p>
              </li>
              <li>
                <p>To protect the personal safety of users of the Service or the public</p>
              </li>
              <li>
                <p>To protect against legal liability</p>
              </li>
            </ul>
            <h2>Security Of Data</h2>
            <p>
              The security of your data is important to us, but remember that no method of
              transmission over the Internet, or method of electronic storage is 100% secure. While
              we strive to use commercially acceptable means to protect your Personal Data, we
              cannot guarantee its absolute security.
            </p>
            <p>Service Providers</p>
            <p>
              We may employ third party companies and individuals to facilitate our Service
              (&ldquo;Service Providers&rdquo;), to provide the Service on our behalf, to perform
              Service-related services or to assist us in analyzing how our Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to perform these tasks on
              our behalf and are obligated not to disclose or use it for any other purpose.
            </p>
            <p>
              <br />
            </p>
            <h2>Analytics</h2>
            <p>
              We may use third-party Service Providers to monitor and analyze the use of our
              Service.
            </p>
            <ul>
              <li>
                <p>
                  Google Analytics is a web analytics service offered by Google that tracks and
                  reports website traffic. Google uses the data collected to track and monitor the
                  use of our Service. This data is shared with other Google services. Google may use
                  the collected data to contextualize and personalize the ads of its own advertising
                  network. You can opt-out of having made your activity on the Service available to
                  Google Analytics by installing the Google Analytics opt-out browser add-on. The
                  add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js)
                  from sharing information with Google Analytics about visits activity.
                </p>
              </li>
            </ul>
            <p>
              For more information on the privacy practices of Google, please visit the Google
              Privacy &amp; Terms web page:
              <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
            </p>
            <p>Links To Other Sites</p>
            <p>
              Our Service may contain links to other sites that are not operated by us. If you click
              on a third party link, you will be directed to that third party&rsquo;s site. We
              strongly advise you to review the Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies
              or practices of any third party sites or services.
            </p>
            <p>Children&rsquo;s Privacy</p>
            <p>Our Service does not address anyone under the age of 18 (&ldquo;Children&rdquo;).</p>
            <p>
              We do not knowingly collect personally identifiable information from anyone under the
              age of 18. If you are a parent or guardian and you are aware that your Children has
              provided us with Personal Data, please contact us. If we become aware that we have
              collected Personal Data from children without verification of parental consent, we
              take steps to remove that information from our servers.
            </p>
            <p>Changes To This Privacy Policy</p>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes
              by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our Service, prior to the
              change becoming effective and update the &ldquo;effective date&rdquo; at the top of
              this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to
              this Privacy Policy are effective when they are posted on this page.
            </p>
            <br/>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <ul>
              <li>
                <p>
                  By email:&nbsp;
                  <a href="mailto:huzefa@saifee-events.com">huzefa@saifee-events.com</a>
                  &nbsp;
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default RegisterPage;
